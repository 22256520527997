.privacy-policy-page {
  .main-content-wrap {
    .privacy-policy-page-container {
      padding: 20px;
      .privacy-sub-heading {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      .privacy-paragraph {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      .privacy-heading {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        border-bottom: 1px solid #00acba;
      }
      .privacy-side-heading{
        font-weight: bold;
        letter-spacing: 1px;

      }
    }
  }
}
