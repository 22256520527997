//Media query for - './pages/login.scss';
.login-page {
  .login-card {
    justify-content: center;
    .logo-box {
      @media only screen and (max-width: 400px) {
        padding: 40px 40px 40px 40px;
      }
      @media only screen and (max-width: 320px) {
        padding: 30px 40px 20px 40px;
        img {
          height: 50px;
        }
      }
    }
  }
  .login-form-box {
    @media screen and (max-width: 400px) {
      padding: 23px 40px;
    }
    @media only screen and (max-width: 320px) {
      padding: 7px 40px;
      .css-11d7ir1-MuiButtonBase-root-MuiButton-root.MuiButton-root {
        padding: 5px;
      }
    }
    .subtitle {
      @media only screen and (max-width: 320px) {
        line-height: 20px;
      }
    }
    .login-button-outer {
      .login-submit-btn {
        @media only screen and (max-width: 320px) {
          gap: 5px;
        }
      }
    }
  }
}

//Media query for - './pages/signup.scss';
.signup-page {
  .head {
    @media screen and (max-width: 700px) {
      padding: 9px 16px 3px;
    }
  }
  .main-box {
    @media screen and (max-width: 870px) {
      padding: 30px 45px;
    }
    @media screen and (max-width: 568px) {
      padding: 30px 25px;
    }
    @media screen and (max-width: 400px) {
      padding: 30px 10px;
    }
    .start-fields {
      @media screen and (max-width: 568px) {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        // flex-direction: column-reverse !important;
        width: 100%;
      }
      .signup-btns {
        width: 160px;
        @media screen and (max-width: 568px) {
          width: 100%;
        }
      }
    }
    .mandatory {
      @media screen and (max-width: 568px) {
        margin-top: 10px;
      }
    }
  }
}

//Media query for - './pages/dashboard.scss';
.dashboard-page {
  .main-content-wrap {
    .card-sample-block {
      .doctor_profile {
        .card-user-desc {
          @media screen and (max-width: 1199px) {
            text-align: center;
          }
          .css-5sfu98-MuiGrid-root {
            @media screen and (max-width: 1199px) {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .start_prescription_btn {
        @media screen and (max-width: 899px) {
          padding: 20px 20px;
        }
        @media screen and (max-width: 410px) {
          padding: 20px 10px;
        }
      }
      .start_prescription_btn button {
        @media screen and (max-width: 410px) {
          font-size: 10px !important;
        }
      }
    }
    .dashboard_statistics_div {
      min-width: 300px;
      @media screen and (max-width: 410px) {
        min-width: auto;
      }
    }
  }
}

//'Media query for -./global.scss';

.default-layout {
  @media screen and (max-width: 568px) {
    background-size: 150%;
  }
  @media screen and (max-width: 450px) {
    background-size: 200%;
  }

  @media screen and (max-width: 568px) {
    padding: 50px 0px 0px 0px;
  }
}

.css-153eyq5-MuiModal-root-MuiDialog-root {
  left: 15px !important;
  right: 15px !important;
}

.Prescribe-icon img {
  @media screen and (max-width: 568px) {
    width: 120px !important;
    height: 44px !important;
  }
}

//'Media query for -'./temp/pages/patient.scss';

.patient-page .main-box {
  @media screen and (max-width: 568px) {
    padding-top: 60px !important;
  }
}

.css-13rlij2-MuiFormLabel-root-MuiInputLabel-root {
  @media screen and (max-width: 670px) {
    top: -5px !important;
  }
}
.css-1lwa3th-MuiFormLabel-root-MuiInputLabel-root {
  @media screen and (max-width: 670px) {
    top: -5px !important;
  }
}

//'Media query for -'./temp/pages/settings.scss';
.setting-page {
  .main-content-wrapper-full {
    .main-content-wrap {
      .payment {
        @media screen and (max-width: 1199px) {
          // padding: 0px !important;
        }
        .add-save-btn {
          width: 198px;
          @media screen and (max-width: 568px) {
            width: 100%;
          }
        }
        .css-1z0rh7t-MuiStack-root {
          @media screen and (max-width: 568px) {
            width: 100%;
          }
        }
      }
      .fp-container {
        padding: 0px !important;
      }
      .exeasting {
        h2 {
          @media screen and (max-width: 600px) {
            text-align: left;
          }
        }
      }
    }
  }
  .settings_main_box {
    .css-6s03sp-MuiGrid-root {
      @media screen and (max-width: 430px) {
        margin-top: 60px;
      }
    }
    .top_tab_out {
      @media screen and (max-width: 510px) {
        padding: 50px 10px 0 10px;
      }
      @media screen and (max-width: 430px) {
        padding: 50px 5px 0 5px;
        .tabs-bar .tab-btn {
          padding: 5px;
        }
      }
    }
    .css-19r6kue-MuiContainer-root {
      @media screen and (max-width: 430px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
  .setting_profile {
    .css-14fm5sv {
      @media screen and (max-width: 1199px) {
        margin-top: 10px;
        padding-top: 10px;
      }
      @media screen and (max-width: 568px) {
        margin-top: 5px;
        padding-top: 5px;
      }
    }
  }
}

//'Media query for -'./temp/pages/product.scss';
.product-page {
  .main-content-wrapper-full {
    .main-content-wrap {
      .product_dropdown_side {
        .filter {
          @media screen and (max-width: 568px) {
            padding: 20px 16px;
          }
        }
      }
      .product_scroll {
        .product_stack {
          .product_out {
            margin: 15px 10px 0 10px;
          }
        }
      }
    }
  }
}

//'Media query for -'./temp/pages/prescription-ordering.scss';

.prescription-ordering .detof_prod .sale .product-name {
  @media screen and (max-width: 320px) {
    font-size: 12px !important;
  }
}
.prescription-ordering .detof_prod .sale .product-price ul {
  @media screen and (max-width: 320px) {
    font-size: 12px !important;
  }
}

.prescription-ordering {
  .detof_prod {
    .Medication {
      .quantity {
        h4 {
          @media screen and (max-width: 320px) {
            font-size: 13px !important;
          }
        }
        span {
          @media screen and (max-width: 320px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

.prescription-ordering {
  .css-1oqqzyl-MuiContainer-root {
    @media screen and (max-width: 430px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .detof_patient {
    .Medication {
      .quantity {
        h4 {
          @media screen and (max-width: 320px) {
            font-size: 13px !important;
          }
        }
        span {
          @media screen and (max-width: 320px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .main-box {
    .ordersteps_main {
      .nameof_customer {
        .radio_btn_stack {
          .radio_grid {
          }
        }
      }
      .steps {
        .place {
          @media screen and (max-width: 350px) {
            font-size: 13px !important;
            line-height: 20px !important;
          }
        }
      }
      .down {
        @media screen and (max-width: 350px) {
          font-size: 15px !important;
        }
      }
    }
  }
  .shipto_Facility_select {
    @media screen and (min-width: 500px) and (max-width: 668px) {
      margin-left: initial;
      height: 30px !important;
      width: 100% !important;
    }
    @media screen and (max-width: 499px) {
      margin-left: initial;
      height: 30px !important;
      width: 100% !important;
    }
    @media screen and (max-width: 390px) {
      margin-left: initial;
      height: 30px !important;
      width: 100% !important;
    }
  }
  .radio_btn_stack > div:first-of-type {
    @media screen and (max-width: 430px) {
      padding-left: 60px;
    }
    @media screen and (max-width: 390px) {
      padding-left: 35px;
    }
    @media screen and (max-width: 330px) {
      padding-left: 20px;
    }
  }
  .radio_btn_stack > div:last-of-type {
    // overflow: hidden;
    @media screen and (max-width: 410px) {
      margin-right: 10px !important;
    }
    @media screen and (max-width: 340px) {
      margin-right: 20px !important;
    }
    @media screen and (max-width: 320px) {
      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding-right: 20px;
      }
    }
  }
  @media screen and (max-width: 568px) {
    .css-1oqqzyl-MuiContainer-root {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.billig_card_btn_res {
  .css-1fmu1sk {
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .css-wfsak8 {
    @media screen and (max-width: 900px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

//'Media query for -'./pages/components/header.scss';
.sidebar-drawer-wrap {
  .sidebar-profile-block {
    .sidebar-profile-details {
      overflow: hidden !important;
      padding: 0px 0px 20px 0px;
    }
    .sidebar-profile-logout{
     text-align: end; 
    }
  }
}

//footer
.footer .footerContainer {
  @media screen and (max-width: 568px) {
    padding: 32px 0px 50px 0px;
  }
}

//'Media query for -'./temp/pages/general_info.scss';

.genral_order {
  .main-content-wrapper-full {
    .main-box {
      .order_general {
        .personal_details {
          h1 {
            @media screen and (max-width: 600px) {
              justify-content: left;
            }
            @media screen and (max-width: 470px) {
              justify-content: left;
              font-size: 20px;
            }
            @media screen and (max-width: 426px) {
              justify-content: left;
              font-size: 17px;
            }
            @media screen and (max-width: 400px) {
              justify-content: left;
              font-size: 15px;
            }
          }
        }
        .prescription_address {
          @media screen and (max-width: 568px) {
            display: block;
            padding: 15px 0px;
          }
          .prescription_address_shipping h3 {
            @media screen and (max-width: 568px) {
              margin-bottom: 15px;
            }
          }
          .prescription_address_billing h3 {
            @media screen and (max-width: 568px) {
              margin-bottom: -15px;
            }
          }
        }
      }
      .orderline_box_new {
        @media screen and (max-width: 568px) {
          margin-top: 20px;
        }
      }
    }
  }
}

//patient contact profile

.responsive-contact-profile {
  .css-19kzrtu {
    @media screen and (max-width: 568px) {
      padding: 24px 0px;
    }
  }
  .main-content-wrap {
    .contact_profile {
      @media screen and (max-width: 568px) {
        margin: 25px 0px;
      }
      .Contact_img {
        @media screen and (max-width: 568px) {
          margin-bottom: 20px;
        }
      }
      .payment_prescriber {
        @media screen and (max-width: 430px) {
          padding: 40px 20px !important;
        }
        @media screen and (max-width: 370px) {
          padding: 40px 10px !important;
        }
      }
      .details_box {
        .contact {
          @media screen and (max-width: 568px) {
            padding-top: 5px;
          }
        }
      }
      .details_border {
        @media screen and (max-width: 568px) {
          padding: 0px 0px;
          margin: 0px 20px;
        }
      }
    }

    .Contact_img {
      @media screen and (max-width: 568px) {
        margin-top: 20px;
      }
    }
    .payment {
      .exeasting {
        .add_new_cart {
          .edit_btn {
            @media screen and (max-width: 568px) {
              margin-top: 15px;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .resposive-edit-profile {
    .details_border {
      @media screen and (max-width: 1199px) {
        padding: 0px 0px 40px 0px;
      }
      @media screen and (max-width: 568px) {
        margin: 0px 0px 0px 7px !important;
        padding: 0px 0px 30px 0px !important;
      }
      @media screen and (max-width: 400px) {
        margin: 0px 0px 0px 7px !important;
        padding: 0px 0px 30px 0px !important;
      }
    }
    .payment_prescriber {
      @media screen and (max-width: 568px) {
        padding: 40px 0px !important;
      }
    }
    .css-117e5y7-MuiGrid-root {
      @media screen and (max-width: 850px) {
        width: 100%;
        padding-top: 14px;
      }
    }
    .css-c40sdo {
      @media screen and (max-width: 850px) {
        width: 100%;
        padding-top: 14px;
      }
    }
    .main_info_alignment {
      @media screen and (max-width: 1199px) {
        width: auto;
        margin-left: 0px;
      }
    }
  }
}

// card css

.add_btnouter {
  @media screen and (max-width: 568px) {
    flex-wrap: wrap;
  }
}

//loader

.loading-indicator:after {
  @media screen and (max-width: 1194px) {
    left: 47%;
  }
  @media screen and (max-width: 610px) {
    left: 46%;
  }
  @media screen and (max-width: 560px) {
    left: 45%;
  }
  @media screen and (max-width: 430px) {
    left: 43%;
  }
  @media screen and (max-width: 393px) {
    left: 42%;
  }
  @media screen and (max-width: 380px) {
    left: 41%;
  }
  @media screen and (max-width: 353px) {
    left: 40%;
  }
}

//prescriber list popup

.p-list-container {
  @media screen and (max-width: 1400px) {
    display: block !important;
    gap: 20px !important;
    margin: 70px 0px 60px 0px !important;
  }
  .p-list-btn {
    @media screen and (max-width: 1400px) {
      margin-top: 20px;
      width: auto !important;
    }
  }
}

.help-page {
  .main-content-wrap {
    @media (min-width: 1024px) {
      padding: 30px !important;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 20px !important;
    }
    @media (max-width: 767px) {
      padding: 10px !important;
    }
    .container {
      @media (min-width: 1024px) {
        padding: 30px 80px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        padding: 20px 60px;
      }
      @media (max-width: 767px) {
        padding: 5px 20px;
      }
      .heading {
        @media (min-width: 1024px) {
          font-size: 25px !important;
          margin: 10px auto !important;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          font-size: 20px !important;
          margin: 8px auto !important;
        }
        @media (max-width: 767px) {
          font-size: 15px !important;
          margin: 6px auto !important;
          }        
      }
      .content {
          @media (min-width: 1024px) {
            margin: 50px auto;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            margin: 35px auto;
          }
          @media (max-width: 767px) {
            margin: 25px auto;
          }
       
          .player-container {
            @media (min-width: 1024px) {
              border-radius: 15px;
            }
            @media (min-width: 768px) and (max-width: 1023px) {
              border-radius: 13px;
            }
            @media (max-width: 767px) {
              border-radius: 10px;
            }
          }

          .player-list {

              @media (min-width: 768px) and (max-width: 1199px) {
                height: auto;
                margin-top: 30px;
                overflow: hidden;
              }
              @media (max-width: 767px) {
                height: auto;
                margin-top: 20px;
                overflow: hidden;

              }
              .player-item {
                  @media (min-width: 768px) and (max-width: 1199px) {
                    text-align: start;
                  }
                  @media (max-width: 767px) {
                    text-align: start;
                  }
               
                  .label {
                      @media (min-width: 768px) and (max-width: 1699px) {
                        font-size: 16px !important;
                        font-weight: 500 !important;
                      }
                      @media (max-width: 767px) {
                        font-size: 14px !important;
                        font-weight: 500 !important;
                      }
                   
                  }
                  .icon {
                      @media (min-width: 768px) and (max-width: 1023px) {
                        scale: 110%;
                      }
                      @media (max-width: 767px) {
                        scale: 100%;
                      }
                  }
              }
          }

          .promotional-video {
              @media (min-width: 768px) and (max-width: 1199px) {
                aspect-ratio: 16 / 9;
              }
              @media (max-width: 767px) {
                aspect-ratio: 16 / 9;
              }
           
          }
          
          .tutorial-video {
              aspect-ratio: 16 / 9;
          }
          
      }

    }
  }
}

// for header
.site-header {
  .navbar-nav {
    ul {
      li {
        .navbar-link {
          @media (min-width: 990px) and (max-width: 1024px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
