.forgot_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 50px 0;
  position: relative;

  .forgot_main {
    text-align: center;
    background-color: #ffffff;
    position: relative;
    z-index: 11;
    padding-top: 30px;

    .logo {
      background: rgba(0, 172, 186, 0.07);
      border: 4px solid #ffffff;
      width: 82px;
      height: 82px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      .logo_img {
        border: 4px solid #ffffff;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    h1 {
      font-family: "Titillium Web";
      font-weight: 600;
      font-size: 30px;
      text-align: center;
      color: #676464;
      z-index: 9999;

      @media only screen and (max-width: 568px) {
        font-size: 25px;
      }
    }

    P {
      color: rgba(103, 100, 100, 0.79);
    }

    .form_box {
      padding-top: 50px;
      z-index: 9999;

      button {
        text-transform: capitalize;
        font-size: 20px;
      }

      @media only screen and (max-width: 568px) {
        padding-top: 30px;
      }

      p.hint {
          font-size: 13px;
          font-weight: 400;
          font-size: 16px;
          padding-top: 15px;  
          text-align: left;
          color: $primary-grey-color;

          span {
            color: #00acba;
            font-weight: bold;
          }
      }
    }

    .back {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: rgba(103, 100, 100, 0.8);
      padding-top: 20px;
      text-decoration: none !important;
      text-align: center;
      text-decoration: none !important;
    }
  }

  .right_bottom_img {
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .left_top_img {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}