.help-page {
    .main-content-wrap {
        padding: 30px;
      .container {
        padding: 50px 100px;
        .heading {
            font-size: 25px !important;
            font-weight: 700 !important;
            margin: 10px auto !important;
        }
        .content {
            // border: 1px solid red;
            padding: auto;
            margin: 50px auto;
            .player-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 15px;
                padding: 0;
            }

            .player-list {
                padding-top: 0;
                min-height: 50vh;
                max-height: 55vh;
                overflow-y: auto;
                .player-item {
                    text-align: end;
                    .label {
                        color: $secondary-dark-color;
                        font-size: 18px !important;
                        font-weight: 500 !important;
                    }
                    .active-label {

                        font-weight: 700 !important;
                    }
                    .icon {
                        color: $primary-color;
                        aspect-ratio: 1 / 1;
                        scale: 120%;
                    }
                }
            }

            .promotional-video {
                aspect-ratio: 16 / 9;
            }
            
            .sixteen-nine {
                aspect-ratio: 16 / 9;
            }
            
        }

      }
    }
  }
  