.prescription-ordering {
  .main-box {
    padding: 88px;
    padding-top: 117px !important;
    margin-bottom: 0px !important;
    height: 100%;
    min-height: 475px;

    @media only screen and (max-width: 990px) {
      padding: 3% !important;
    }

    @media only screen and (min-width: 991px) and (max-width: 1270px) {
      padding: 4% !important;
    }

    .prescription_heading {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 76px;
      text-align: center;
      text-transform: capitalize;
      color: #00acba;
      padding-bottom: 20px;

      @media only screen and (max-width: 668px) {
        font-size: 25px;
        padding: 10px 0 0 0;
      }
    }

    /*-------- ORDER LINE SECTION SCSS HERE --------*/
    .stepsOuter {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .orderline_box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 0;
      margin: 0 12px;

      @media only screen and (max-width: 668px) {
        padding: 20px 0;
      }

      .order_line {
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: #dedede;
        z-index: -1;
      }

      .order {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #ffffff;
        border: 8px solid #528097;

        @media only screen and (max-width: 668px) {
          width: 40px;
          height: 40px;
          border: 3px solid #528097;
        }
      }

      .order_fill {
        background-color: #528097;
      }
    }

    /*-------- ORDER LOCATION NAME SECTION SCSS HERE --------*/
    .order_location {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      margin-top: -36px;
      margin-left: 10px;
      margin-right: 10px;

      @media only screen and (max-width: 668px) {
        margin-top: -20px;
      }

      .location {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 127.1%;
        text-transform: capitalize;
        color: #00acba;
        width: 1015px;

        @media only screen and (max-width: 668px) {
          font-size: 16px;
        }

        @media only screen and (min-width: 669px) and (max-width: 991px) {
          font-size: 21px;
        }
      }

      .start {
        text-align: left;

        .top {
          margin-left: 10px;
        }

        .bottom {
          margin-left: 8px;
        }
      }

      .end {
        text-align: right;

        .top {
          margin-right: -1px;
        }

        .bottom {
          margin-right: -8px;
        }
      }
    }

    /*------ ORDER STEPS SECTION SCSS HERE ------*/
    .ordersteps_main {
      padding: 84px 88px 60px 88px;
      background-color: rgba(217, 137, 45, 0.05);
      border-radius: 0px 50px;
      margin-top: 77px;

      @media only screen and (min-width: 411px) and (max-width: 990px) {
        padding: 44px 15px 44px 15px;
      }
      @media only screen and (max-width: 411px) {
        padding: 44px 0px 44px 0px;
      }

      @media only screen and (min-width: 991px) and (max-width: 1570px) {
        padding: 84px 25px 60px 25px;
      }

      .steps_main {
        @media only screen and (max-width: 750px) {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media only screen and (max-width: 668px) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .steps_way {
        position: relative;

        .steps_line {
          position: absolute;
          height: 100%;
          border: 2px dashed #00acba;
          left: 20px;
          left: 60px;
          z-index: -1;

          // @media only screen and (max-width: 994px) {
          //   display: none;
          // }
          @media only screen and (max-width: 539px) {
            display: none;
          }
          @media only screen and (max-width: 991px) {
            left: 33px;
          }
        }

        .contact_by_phone {
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 14px;
          line-height: 133.1%;
          color: #528097;
          text-align: center;
          padding-top: 10px;

          span {
            font-size: 20px;
            color: #00acba;
          }
        }
      }

      /*------ ORDER STEP ONE SECTION SCSS HERE ------*/
      .steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        background: #528097;
        border-radius: 50px;
        position: relative;

        // margin-bottom: 20px;

        @media only screen and (min-width: 485px) and (max-width: 991px) {
          height: 60px;
        }

        @media only screen and (max-width: 485px) {
          height: 60px !important;
        }

        .step_start {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 120px;
          width: 120px;
          border-radius: 50%;
          background-color: #00acba !important;
          border: 12px solid #fdf9f5;
          font-family: 'Titillium Web';
          font-weight: 700;
          font-size: 22px;
          line-height: 46px;
          text-transform: uppercase;
          color: #fdf9f5;
          min-width: 70px;

          @media only screen and (max-width: 668px) {
            height: 70px;
            width: 60px;
            border: 4px solid #fdf9f5;
            font-size: 12px;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            height: 70px;
            width: 70px;
            font-size: 16px;
            border: 4px solid #fdf9f5;
          }
        }

        .step_start_border {
          border: 8px solid rgb(233, 240, 235) !important;

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            border: 4px solid rgb(233, 240, 235) !important;
          }
        }

        .place {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 61px;
          text-align: center;
          color: #fdf9f5;

          @media only screen and (max-width: 668px) {
            font-size: 15px;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            font-size: 24px;
          }
        }

        .back_processing {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          border: 7px solid #fdf9f5;
          margin-right: 4px;
          background-color: #00acba;
          display: flex;
          align-items: center;
          justify-content: center;
          // min-width: 53px;

          @media only screen and (max-width: 668px) {
            height: 50px;
            width: 50px;
            border: 6px solid #fdf9f5;
            margin-right: 5px;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            border: 6px solid #fdf9f5;
            height: 50px;
            width: 50px;
          }
        }

        .back_processingin {
          border: 9px solid #00acba;
          background-color: #fff !important;
          height: 100%;
          width: 100%;
          border-radius: 50%;

          @media only screen and (max-width: 668px) {
            border: 8px solid #00acba;
            width: 100%;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            border: 8px solid #00acba;
            width: 95%;
          }
        }

        .order_fill_steps {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          position: relative;
          border: 7px solid #fdf9f5;
          margin-right: 4px;
          background-color: #00acba;
          display: flex;
          align-items: center;
          justify-content: center;

          .order_fill_right {
            position: absolute;
            width: 13px;
            top: 21px;
            left: 6px;
            height: 6px;
            background-color: #fff;
            transform: rotate(43deg);
          }

          .order_fill_rightr {
            position: absolute;
            width: 23px;
            top: 17px;
            left: 11px;
            height: 6px;
            background-color: #fff;
            transform: rotate(130deg);
          }
        }
      }
      .patient_card_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .empty_margin {
          height: 100%;
          width: 120px;
        }
        .patient_data_info {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        @media only screen and (max-width: 992px) {
          .empty_margin {
            height: 100%;
            width: 70px;
          }
        }
        @media only screen and (max-width: 539px) {
          .empty_margin {
            width: 0px;
          }
        }
        .product_panel {
          @media only screen and (max-width: 539px) {
            padding: 0px 20px !important;
          }
        }
      }

      .nameof_customer_set {
        padding-bottom: 15px !important;
      }

      .nameof_customer {
        padding: 15px 0;

        .date_pikker {
          @media only screen and (max-width: 668px) {
            flex-direction: column;
          }

          .date_input {
            width: 100%;
            margin: auto;
            text-align: center;
            @media screen and (max-width: 600px) {
              width: 80%;
            }
            .centered-text {
              input {
                text-align: center !important;
                color: black !important;
                font-size: 20px !important;
              }
            }
          }
        }
        .prescriber_text_filed {
          width: 100%;
          gap: 25px;
          @media screen and (max-width: 600px) {
            justify-content: center !important;
          }

          .radio_grid {
            text-align: left;

            ul {
              list-style: none;
              display: flex;
              align-items: center;
              margin: 0;
              gap: 10px;
              margin-left: -40px;

              @media only screen and (max-width: 1400px) {
                flex-wrap: wrap;
                width: 150%;
              }
              @media only screen and (max-width: 1000px) {
                flex-wrap: wrap;
                width: 100%;
              }

              .dash {
                @media only screen and (max-width: 1100px) {
                  display: none;
                }
              }

              .edit {
                .edit_icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 20px;
                  width: 20px;
                  border: 2px solid #00acba;
                  border-radius: 5px;
                  margin-right: 5px;
                }

                p {
                  font-family: 'Titillium Web';
                  font-weight: 700;
                  font-size: 18px;
                  text-decoration-line: underline;
                  text-transform: capitalize;
                  color: #00acba;

                  @media only screen and (max-width: 400px) {
                    font-size: 14px;
                  }
                }
              }

              li {
                display: flex;
                align-items: baseline;
                justify-content: center;
                white-space: nowrap;
                color: #528097;
                font-size: 14px;

                @media only screen and (max-width: 510px) {
                  font-size: 12px;
                  white-space: pre-wrap;
                }

                span {
                  font-family: 'Titillium Web';
                  font-weight: 700;
                  font-size: 16px;
                  text-transform: capitalize;
                  color: #528097;
                  margin-right: 5px;
                  display: flex;
                  align-items: center;

                  @media only screen and (max-width: 400px) {
                    font-size: 13px;
                  }
                }
              }
            }

            label {
              span {
                svg {
                  fill: #528097 !important;

                  path {
                    fill: #00acba !important;
                  }
                }
              }
            }
          }
        }
        .radio_btn_stack {
          width: 100%;
          gap: 25px;
          @media screen and (max-width: 600px) {
            justify-content: flex-start !important;
          }

          .radio_grid {
            text-align: left;

            ul {
              list-style: none;
              display: flex;
              align-items: center;
              margin: 0;
              gap: 10px;
              margin-left: -40px;

              @media only screen and (max-width: 1400px) {
                flex-wrap: wrap;
                width: 150%;
              }
              @media only screen and (max-width: 1000px) {
                flex-wrap: wrap;
                width: 100%;
              }

              .dash {
                @media only screen and (max-width: 1100px) {
                  display: none;
                }
              }

              .edit {
                .edit_icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 20px;
                  width: 20px;
                  border: 2px solid #00acba;
                  border-radius: 5px;
                  margin-right: 5px;
                }

                p {
                  font-family: 'Titillium Web';
                  font-weight: 700;
                  font-size: 18px;
                  text-decoration-line: underline;
                  text-transform: capitalize;
                  color: #00acba;

                  @media only screen and (max-width: 400px) {
                    font-size: 14px;
                  }
                }
              }

              li {
                display: flex;
                align-items: baseline;
                justify-content: center;
                white-space: nowrap;
                color: #528097;
                font-size: 14px;

                @media only screen and (max-width: 510px) {
                  font-size: 12px;
                  white-space: pre-wrap;
                }

                span {
                  font-family: 'Titillium Web';
                  font-weight: 700;
                  font-size: 16px;
                  text-transform: capitalize;
                  color: #528097;
                  margin-right: 5px;
                  display: flex;
                  align-items: center;

                  @media only screen and (max-width: 400px) {
                    font-size: 13px;
                  }
                }
              }
            }

            label {
              span {
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 24px;
                // color: #263238;

                // font-family: 'Titillium Web';
                // font-weight: 600;
                // font-size: 30px;
                // text-transform: capitalize;
                // color: #02223a;

                // @media only screen and (max-width: 668px) {
                //   font-size: 15px;
                // }

                // @media only screen and (min-width: 669px) and (max-width: 991px) {
                //   font-size: 26px;
                // }

                svg {
                  fill: #528097 !important;

                  path {
                    fill: #00acba !important;
                  }
                }
              }
            }
          }
        }

        h3 {
          font-family: 'Titillium Web';
          font-weight: 600;
          font-size: 30px;
          line-height: 53px;
          text-align: center;
          text-transform: capitalize;
          color: #02223a;
          white-space: nowrap;
          margin-right: 15px;

          @media only screen and (max-width: 668px) {
            font-size: 22px;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            font-size: 25px;
          }
        }
      }

      /*------ DOWN ARROW SECTION SCSS HERE ------*/
      .down {
        padding: 30px 0;

        @media only screen and (max-width: 668px) {
          padding-top: 0;
          padding-bottom: 30px;
        }
      }

      /*------ ADD PATIENT SECTION SCSS HERE ------*/
      .add_patient_main {
        background: rgba(65, 154, 160, 0.1);
        border-radius: 0 0 0 40px;
        margin: 20px;
        padding: 100px 78px;
        position: relative;
        margin-bottom: 60px;

        @media only screen and (min-width: 1400px) {
          padding: 100px 3% 100px 3%;
        }
        @media only screen and (max-width: 1400px) {
          padding: 100px 3% 100px 3%;
        }
        @media only screen and (max-width: 669px) {
          margin: 0 !important;
          padding: 100px 1% 100px 1%;
        }
        .cornar_box {
          position: absolute;
          background-color: #00acba;
          font-family: 'Titillium Web';
          font-weight: 600;
          font-size: 30px;
          line-height: 52px;
          text-transform: capitalize;
          color: #ffffff;
          width: 353.38px;
          height: 61px;
          top: -30px;
          right: -22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 20px;

          @media only screen and (max-width: 668px) {
            font-size: 20px;
            width: 100%;
            height: 40px;
            top: -16px;
            right: 0;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            font-size: 30px;
            width: 339.38px;
            height: 45px;
            top: -22px;
            right: -20px;
          }
        }

        .deleat_icon {
          position: absolute;
          bottom: 20px;
          right: 30px;
        }

        .steps {
          .place {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media only screen and (max-width: 668px) {
              gap: 5px;
            }

            .plus_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #00acba;
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .place_searchbar {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 15px;
            padding: 0 15px;

            @media only screen and (max-width: 668px) {
              gap: 5px;
              padding: 0px 0px 0px 10px;
            }

            .recent-order-table-layouts {
              display: flex;
              align-items: center;
              gap: 15px;

              .MuiInputBase-formControl {
                background-color: #fff;
                border-radius: 24px 24px 24px 24px !important;
                height: 39px !important;
                width: 300px !important;
                padding-left: 20px;
                color: #263238;
                @media only screen and (min-width: 991px) and (max-width: 1065px) {
                  width: 220px !important;
                }
                @media only screen and (min-width: 721px) and (max-width: 840px) {
                  width: 200px !important;
                }
                @media only screen and (min-width: 669px) and (max-width: 721px) {
                  width: 150px !important;
                }
                @media only screen and (min-width: 485px) and (max-width: 630px) {
                  width: 200px !important;
                }
                @media only screen and (min-width: 400px) and (max-width: 485px) {
                  width: 150px !important;
                  margin-left: -20px;
                }
                @media only screen and (min-width: 370px) and (max-width: 400px) {
                  width: 120px !important;
                  margin-left: -25px;
                }
                @media only screen and (max-width: 370px) {
                  width: 100px !important;
                  margin-left: -25px;
                }
                @media screen and (max-width: 330px) {
                  width: 90px !important;
                }
                // @media only screen and (min-width:520px) and (max-width:534px) {
                //   width: 80px !important;
                // }
                // @media only screen and (min-width:495px) and (max-width:520px) {
                //   width: 70px !important;
                // }
                // @media only screen  and (max-width:495px) {
                //   width: 50px !important;
                // }
              }
              .MuiInputBase-formControl ::placeholder {
                color: #263238;
                opacity: 1;
              }
              .active {
                border-radius: 24px 24px 0px 0px !important;
              }
              .userr_icon {
                height: 30px;
                width: 30px;
                @media only screen and (max-width: 668px) {
                  display: none;
                }
              }
            }

            .start_prescription_btn {
              // display: block;
              @media only screen and (max-width: 1420px) {
                display: none !important;
              }
            }
          }
        }
      }

      .down {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Titillium Web';
        font-weight: 700;
        font-size: 30px;
        line-height: 61px;
        text-transform: capitalize;
        color: #00acba;

        @media only screen and (max-width: 668px) {
          font-size: 20px;
        }

        @media only screen and (min-width: 669px) and (max-width: 991px) {
          font-size: 25px;
        }

        .last_add_block {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #528097;
          margin-right: 15px;

          @media only screen and (max-width: 668px) {
            width: 30px;
            height: 30px;
          }

          @media only screen and (min-width: 669px) and (max-width: 991px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      .preview_button {
        cursor: pointer;
      }

      .down_last {
        padding-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .detof_customer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #528097 !important;
    margin: 50px 0;
    padding: 4px 7px;
    border-radius: 30px;

    .customer-number {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00acba;
      border: 4px solid #fff;
      font-family: 'Titillium Web';
      font-weight: 700;
      font-size: 25px;
      text-transform: capitalize;
      color: #fff;
    }

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      padding-left: 0;
      gap: 10px;

      li {
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        color: #fff;
      }
    }

    .down-arrow {
      height: 20px;
      width: 40px;
      display: flex;
      align-items: center;
    }
  }

  .detof_prod {
    background: #528097;
    margin: 35px 0px 0px 0px;
    border-radius: 25px !important;
    margin-bottom: -50px;

    .MuiAccordion-root {
      background: #528097 !important;
      border-radius: 0 0 50px 50px !important;

      .MuiSvgIcon-root {
        color: #00acba !important;
        font-size: 40px;
      }
    }

    .product {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 90%;
      border-bottom: 2px solid #00acba;
      padding: 10px 0px 5px 0;
      margin: 0px 14px;
      margin: auto;
      p {
        margin: 0;
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        color: #fff;
      }
    }

    .sale {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 1% 0;

      .product-name {
        display: flex;
        align-items: center;
        gap: 15px;
        font-family: 'Titillium Web';
        font-weight: 400;
        text-transform: capitalize;
        color: #ffffff;

        span {
          display: flex;
          align-items: flex-end;
        }
      }

      .product-price {
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          padding-left: 15px !important;
          gap: 20px;
          margin: 0px;
          margin-right: -15px;

          li {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 400;
            text-transform: capitalize;
            color: #ffffff;
          }
        }
      }
    }

    .devider {
      height: 15px;
      display: flex;
      align-items: center;
      width: 100%;

      .devider-line {
        border: 1px dashed #ffffff;
        width: 100%;
      }
    }

    p {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
      color: #ffffff;
      text-align: center;
      margin: 10px 0 10px 0;
    }

    .Medication {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
      }

      .quantity {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        h4 {
          font-family: 'Titillium Web';
          font-weight: 700;
          font-size: 18px;
          text-transform: capitalize;
          color: #ffffff;
          margin-bottom: 8px;
        }

        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 18px;
          text-transform: capitalize;
          color: #ffffff;
        }

        p {
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;
          color: #ffffff;
          margin: 0;
          padding: 0;
          text-align: left;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          padding-left: 0;
          gap: 10px;

          li {
            display: flex;
            align-items: center;
            font-family: 'Titillium Web';
            font-weight: 400;
            font-size: 18px;
            text-transform: capitalize;
            color: #ffffff;

            span {
              height: 20px;
              width: 20px;
              border-radius: 50%;
              background-color: #00acba;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0;
            }
          }
        }
      }

      .dash {
        border: 1px dashed #00acba;
        height: 150px;
      }
    }

    .button-two {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      list-style: none;

      li {
        .btn {
          border-radius: 8px;
          background: none;
          border: 2px solid #ffffff;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 29px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          padding: 3px 15px;
        }

        .save-btn {
          background-color: #fff;
          color: #00acba;
        }
      }
    }
  }

  .detof_patient {
    width: 100%;
    background: #528097;
    // margin: 35px 0px;
    margin-top: 35px;
    border-radius: 30px;

    // @media only screen and (min-width:560px) and (max-width: 660px) {
    //   margin-left: 30px;
    //   padding-right: 50px;
    // }
    // @media only screen  and (max-width: 560px) {
    //   margin-left: 50px;
    //   padding-right: 70px;
    // }
    .detof_customer_new {
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      background: #528097 !important;
      border-radius: 50px;
      padding: 8px 8px;

      .dob_text {
        @media screen and (max-width: 1200px) {
          margin-right: 0px !important;
        }
      }
      .delete_icon {
        @media screen and (max-width: 1200px) {
          margin-right: -10px !important;
        }
      }
      //added
      .user_icon {
        @media screen and (max-width: 1200px) {
          margin-left: 10px !important;
        }
        @media screen and (max-width: 320px) {
          margin-left: 2px !important;
        }
      }
      .pat_name {
        @media screen and (max-width: 1200px) {
          width: 42% !important;
        }
      }

      .customer-number {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00acba;
        border: 4px solid #fff;
        font-family: 'Titillium Web';
        font-weight: 700;
        font-size: 25px;
        text-transform: capitalize;
        color: #fff;
      }

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        align-items: center;
        padding-left: 0;
        gap: 10px;
        width: 80%;
        margin: auto;
        @media screen and (max-width: 600px) {
          gap: 7px;
          padding-right: 0px;
        }

        li {
          font-family: 'Titillium Web';
          font-weight: 600;
          font-size: 18px;
          text-transform: capitalize;
          color: #fff;

          @media screen and (max-width: 450px) {
            font-size: 14px !important;
          }
          //added
          @media screen and (max-width: 400px) {
            font-size: 12px !important;
          }
          @media screen and (max-width: 320px) {
            font-size: 11px !important;
          }
        }
      }

      .down-arrow {
        height: 20px;
        width: 40px;
        display: flex;
        align-items: center;
      }
    }

    .product {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      border-bottom: 2px solid #00acba;
      padding: 10px 0 5px 0;

      p {
        margin: 0;
        font-family: 'Titillium Web';
        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        color: #fff;
      }
    }

    .sale {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .product-name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-family: 'Titillium Web';
        font-weight: 400;
        font-size: 20px;
        text-transform: capitalize;
        color: #ffffff;

        span {
          display: flex;
          align-items: flex-end;
        }
      }

      .product-price {
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          padding-left: 0;
          gap: 10px;

          li {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            text-transform: capitalize;
            color: #ffffff;
          }
        }
      }
    }

    .devider {
      height: 15px;
      display: flex;
      align-items: center;
      width: 100%;

      .devider-line {
        border: 1px dashed #ffffff;
        width: 100%;
      }
    }

    p {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
      color: #ffffff;
      text-align: center;
      margin: 10px 0 10px 0;
    }

    .Medication {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;

      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
      }

      .MuiOutlinedInput-root {
        border: 1px solid #ffffff !important;
        color: #fff !important;
        height: 36px !important;
      }

      label[data-shrink='true'].Mui-focused {
        background: transparent;
        visibility: hidden;
      }

      label[data-shrink='false'].Mui-focused {
        color: 1px solid #ffffff !important;
        background: transparent;
      }

      .MuiInputLabel-root {
        visibility: hidden;
      }

      .MuiOutlinedInput-input {
        color: #fff !important;
        height: 36px !important;
      }

      .quantity {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;

        h4 {
          font-family: 'Titillium Web';
          font-weight: 700;
          font-size: 18px;
          text-transform: capitalize;
          color: #ffffff;
          margin-bottom: 8px;
        }

        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 8px;
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 18px;
          text-transform: capitalize;
          color: #ffffff;
        }

        p {
          font-family: 'Titillium Web';
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;
          color: #ffffff;
          margin: 0;
          padding: 0;
          text-align: left;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          padding-left: 0;
          gap: 10px;

          li {
            display: flex;
            align-items: center;
            font-family: 'Titillium Web';
            font-weight: 400;
            font-size: 18px;
            text-transform: capitalize;
            color: #ffffff;

            span {
              height: 20px;
              width: 20px;
              border-radius: 50%;
              background-color: #00acba;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0;
            }
          }
        }
      }

      .dash {
        border: 1px dashed #00acba;
        height: 150px;
      }
    }

    .button-two {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      list-style: none;
      padding: 20px 0 10px 0;

      li {
        .btn {
          border-radius: 8px;
          background: none;
          border: 2px solid #ffffff;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 29px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          padding: 3px 15px;
        }

        .save-btn {
          background-color: #fff;
          color: #00acba;
        }
      }
    }
  }

  #panel1a-header {
    margin: 0;

    @media only screen and (max-width: 660px) {
      padding: 0 !important;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: rgba(249, 249, 249, 0);
  }
  /*------ ADD NEW SCSS AFTER LONG TIME HERE ------*/
  .search_input {
    .MuiIconButton-root.MuiAutocomplete-popupIndicator {
      margin-right: 0px;
    }

    // input {
    //   outline: none !important;
    //   border: none !important;
    //   box-shadow: none !important;
    //   display: flex;
    //   align-items: center;
    //   height: 39px;
    //   border: none;
    //   line-height: 22px;
    //   width: 300px;
    //   border-radius: 24px 24px 24px 24px;
    //   box-sizing: content-box;
    //   padding: 0 4px 0 10px;
    //   outline: 0;
    //   overflow: hidden;
    //   &:focus {
    //     border-radius: 24px 24px 0px 0px;
    //   }
    // }
    .MuiInputBase-root.MuiInput-root:hover {
      border: none;
      border-bottom: none !important;
    }

    .MuiInputBase-root.MuiInput-root:before {
      border-bottom: none !important;
    }

    .MuiInputBase-root.MuiInput-root::after {
      border-bottom: none !important;
    }

    .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled)::before {
      border-bottom: none !important;
    }

    .MuiOutlinedInput-root {
      padding-right: 9px !important;
    }

    .MuiOutlinedInput-root {
      height: 48px !important;
      width: 260px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 24px !important;

      // @media only screen and (max-width: 900px) {
      //   width: 100% !important;
      // }

      @media only screen and (min-width: 735px) and (max-width: 900px) {
        width: 150px !important;
      }

      @media only screen and (min-width: 900px) and (max-width: 1064px) {
        width: 240px !important;
      }

      @media only screen and (min-width: 1064px) and (max-width: 1420px) {
        width: 300px !important;
      }

      @media only screen and (max-width: 735px) {
        width: 100px !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      border-radius: 24px;
    }

    label[data-shrink='true'].Mui-focused {
      background: transparent;
      visibility: hidden;
    }

    label[data-shrink='false'].Mui-focused {
      color: #676464 !important;
      background: transparent;
    }

    .MuiInputLabel-root {
      top: -4px !important;
    }
  }

  .MuiAutocomplete-input {
    padding: 0px !important;
  }

  .search-bar-autocompleted {
    position: relative;
    border-radius: 24px 24px 24px 24px;

    .active {
      border-radius: 24px 24px 0px 0px;
    }

    .MuiInputBase-root.MuiInput-root::after {
      border-bottom: 0px !important;
    }

    .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled)::before {
      border-bottom: 0px !important;
    }

    .MuiInputBase-root-MuiInput-root {
      border-bottom: 0px !important;
    }

    .MuiInput-input {
      padding: 0px !important;
    }

    .search-bar-btn {
      position: absolute;
      right: 8px;
      bottom: 2px;
      pointer-events: none;
    }

    .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      background: rgba(255, 255, 255, 0.08);
      border: none !important;
      border-radius: 24px 24px 0px 0px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      border-radius: 24px 24px 0px 0px;
    }
  }

  .search_text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;

    color: #676464;
  }

  .searchIconOuter {
    position: relative !important;

    input {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      text-transform: capitalize;

      color: #676464;
    }

    .icon_search {
      position: absolute !important;
      right: 15px;
      top: 15px;
    }
  }
}

.patientNewOrder {
  min-height: 100px;
  flex-direction: column !important;
  margin-bottom: 35px;

  @media only screen and (max-width: 668px) {
    min-height: 10px;
  }
  @media only screen and (max-width: 990px) {
    min-height: 40px;
  }
  .MuiAccordion-root {
    background: #528097 !important;
    margin-top: 35px;
    // margin: 35px 0px;
    border-radius: 50px !important;

    .MuiSvgIcon-root {
      color: #00acba !important;
      font-size: 40px;
    }
  }

  .MuiPaper-root.MuiAccordion-root.Mui-expanded:first-of-type {
    margin-top: 16px;
  }

  .MuiPaper-root.MuiAccordion-root.Mui-expanded:last-of-type {
    margin-bottom: 16px;
  }
}

.autoOptionSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  border-radius: 0px 0px 24px 24px;

  @media screen and (min-width: 669px) and (max-width: 840px) {
    gap: 0px;
    padding: 5px 5px;
  }
  @media screen and (max-width: 629px) {
    gap: 0px;
    padding: 5px 5px;
  }

  .custom_option_heading {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    color: #676464;
    // word-break: break-all;
    width: 120px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 669px) and (max-width: 840px) {
      font-size: 14px;
    }
    @media screen and (max-width: 629px) {
      font-size: 12px;
    }
  }
}

.create_order_place {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media only screen and (max-width: 668px) {
    gap: 5px;
  }

  .order_plus_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00acba;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    text-align: center;

    @media only screen and (max-width: 668px) {
      width: 30px;
      height: 30px;
    }

    // @media only screen and (max-width: 1270px) {
    //   display: block;
    // }
    @media only screen and (min-width: 1420px) {
      display: none;
    }
  }
}

.radio_btn_stack .radio_grid label span {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 25px;
  line-height: 24px;
  color: #263238;
  font-family: 'Titillium Web';
  font-weight: 600;
  text-transform: capitalize;
  color: #02223a;
}
.prescriber_text_filed .radio_grid label span {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 25px;
  line-height: 24px;
  color: #263238;
  font-family: 'Titillium Web';
  font-weight: 600;
  text-transform: capitalize;
  color: #02223a;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
}

#prescribing_date .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 3px solid #00acba !important;
  border-radius: 8px 0px;

  @media only screen and (max-width: 668px) {
    padding: 15px;
    top: -8px !important;
  }
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
}
.MuiOutlinedInput-root {
  width: 100%;
  height: 50px !important;
  @media only screen and (max-width: 668px) {
    margin: auto;
    // width: 70%;
    height: 30px !important;
  }
}
.ordersteps_main_container {
  @media only screen and (max-width: 668px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.steps_way_container {
  @media screen and (max-width: 669px) {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.plus_icon_img {
  @media only screen and (max-width: 519px) {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }
}

#detof_customer_new_icon_p {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.css-cuefkz-MuiContainer-root {
  @media screen and (max-width: 450px) {
    padding: 0 !important;
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    margin-top: 10px !important;
  }
}
#shipto_Facility_box {
  @media screen and (max-width: 846px) {
    text-align: left;
    margin-right: 0px !important;
  }
}
.shipto_Facility_select {
  @media screen and (min-width: 500px) and (max-width: 668px) {
    margin-left: initial;
    height: 30px !important;
    width: 200px !important;
  }
  @media screen and (max-width: 499px) {
    margin-left: initial;
    height: 30px !important;
    // width: 200px !important;
    width: 165px !important;
  }
  @media screen and (max-width: 390px) {
    margin-left: initial;
    height: 30px !important;
    // width: 150px !important;
    width: 130px !important;
  }
}
.menu_shipto_facility {
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}
