.heading-01 {
  font-size: 50px;
  font-weight: bold;
}

.heading-02 {
  font-size: 34px;
  font-weight: bold;
}

.heading-03-v1 {
  font-size: 29px !important;
  font-weight: bold !important;
}

.heading-03-v2 {
  font-size: 32px !important;
  font-weight: 500 !important;
}

.--heading-v4-padding {
  padding-top: 0px;
}

.heading-04-v1 {
  font-size: 28px !important;
  font-weight: 600 !important;
}

.heading-04-v2 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.heading-03-v1-size{
  font-size: 35px !important;
  font-weight: 600 !important;
}

.paragraph-01 {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.paragraph-02 {
  font-size: 19px;
  font-weight: 400;
}

.paragraph-03-v1 {
  font-size: 18px;
  font-weight: 500;
}

.paragraph-03-v2 {
  font-size: 18px;
  font-weight: 400;
}

.paragraph-04 {
  font-size: 16x;
  font-weight: 400;
}