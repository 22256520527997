.chat-floating-icon {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1001;
    display: none; //to hide the floating icon

    @media screen and (max-width:1024px) {
        bottom: 10px;
        right: 10px;
        display: none; //to hide the floating icon
    }
}