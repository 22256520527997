.MuiDialogContent-root {
    border-bottom: 0 !important;
    border-top: 0 !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  
  .MuiDialog-paper {
    margin:0px !important
  }
  .MuiDialog-paper {
    padding: 0px !important
  
  }
  .MuiTypography-root.MuiDialogTitle-root{
    padding: 0px !important
  }
  .create_patient_content {
    height: 100%;
    // scrollbar-width: none;
  
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    // &::-webkit-scrollbar-track {
    //   background: #fff;
    // }
  
    // &::-webkit-scrollbar-thumb {
    //   background-color: #fafafa;
    //   border-radius: 30px;
    // }
  }
  
  .create_patient_info {
    padding: 0 70px;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      padding: 0px;
    }
  
    .MuiGrid-item {
      padding-top: 28px ;
    }
  
    .modal_heading_main {
      margin: 10px 0 40px;
      white-space: nowrap;
  
      .heading_bottom {
        color: #263238;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
        padding: 12px 0;
        margin-bottom: 30px;
        width: 50%;
        border-bottom: 2px solid $primary-color;
  
        @media screen and (max-width: 568px) {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0px;
          border-bottom: 2px solid #00acba;
        }
      }
  
      .heading_bottom_without_border {
        color: #263238;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
        padding: 12px 0;
        margin-bottom: 30px;
        width: 50%;
  
        @media screen and (max-width: 568px) {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 0px;
        }
      }
  
      @media screen and (max-width: 768px) {
        margin: 20px 0 30px;
      }
    }
  
    .field-info-details-desc {
      color: #263238;
      font-size: 22px;
      font-weight: 600;
  
      span {
        display: block;
        color: #959595;
        font-size: 16px;
        font-weight: 400;
      }
    }
  
    .divider-horizontal {
      border: 1.5px solid #f1f1f1;
      margin: 48px 0 25px;
    }
  
    .inputs_fields_ratio {
      display: flex;
      flex-direction: row;
      gap: 21px;
  
      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        gap: 28px;
      }
  
      // @media screen and (max-width: 1024px) {
      //     margin-bottom: 15px;
      // }
  
      ul {
        padding-left: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        width: 100%;
  
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
  
        li {
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
          }
        }
  
        .popup_btns {
          display: flex !important;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          gap: 15px;
        }
      }
  
      .MuiOutlinedInput-root {
        border-radius: 8px;
  
        &.Mui-focused {
          fieldset {
            border-color: $primary-color;
          }
        }
  
        &.Mui-error {
          border-color: #d32f2f !important;
  
          fieldset {
            border-color: #d32f2f;
          }
        }
      }
  
      label.MuiInputLabel-formControl {
        background: #fff;
  
        &.MuiFormLabel-colorSuccess.Mui-focused {
          color: $primary-color;
        }
  
        &.MuiFormLabel-colorPrimary.Mui-focused {
          color: $primary-color;
        }
  
        &.MuiFormLabel-colorPrimary.Mui-error.Mui-focused {
          color: red;
        }
      }
  
      .mandatory {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #263238;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        white-space: nowrap;
  
        span {
          color: $primary-color;
        }
  
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
  
        @media screen and (max-width: 820px) {
          justify-content: center;
          margin-right: 0px !important;
        }
      }
  
      .outerBtn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        flex: 1;
        list-style: none;
  
        .btn {
          font-size: 18px;
          letter-spacing: 2px;
          width: 165px;
          height: 49px;
          flex: 1;
          border: 1px solid #032439;
          border-radius: 8px;
          background: none;
        }
  
        .reg {
          color: #fff;
          border: none;
          background-color: $primary-color;
        }
  
        .mandatory {
          font-family: "Titillium Web";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #263238;
          white-space: nowrap;
        }
      }
  
      .add_meditation {
        height: 50px;
        width: 146px;
  
        @media screen and (max-width: 568px) {
          width: 100%;
        }
      }
  
      .add_meditation_bg {
        height: 50px;
        width: 230px;
  
        @media screen and (max-width: 568px) {
          width: 100%;
        }
      }
  
      h5 {
        font-family: "Titillium Web";
        font-weight: 500;
        font-size: 15px;
        color: #959595;
      }
  
      p {
        font-family: "Titillium Web";
        font-weight: 400;
        font-size: 14px;
        color: #959595;
      }
    }
  }
  
  .credit_card {
    border-bottom: 1px solid #00acba;
    padding-bottom: 15px;
    padding-top: 40px;
    margin-bottom: 20px;
  
    p {
      font-family: "Titillium Web";
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
      color: #263238;
    }
  
    @media screen and (max-width: 1200px) {
      padding-top: 0;
      margin-bottom: 15px;
    }
  }
  
  .cvc_input {
    position: relative;
  }
  
  .MuiPaper-elevation {
    box-shadow: none !important;
  }
  
  .forgotpwd-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #ffffff;
  }
  
  .check-box-custom-remember-white {
    display: block;
    position: relative;
    height: 25px;
    width: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .check-box-custom-remember-white input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark_white {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00c6c0;
    border-radius: 5px;
  }
  
  .check-box-custom-remember-white:hover input ~ .checkmark_white:after {
    display: block;
    border: 1px solid #cbcbcb;
    border-width: 0 3px 3px 0;
  }
  
  .check-box-custom-remember-white:active input ~ .checkmark_white:after {
    display: block;
    border: 1px solid #00c6c0;
    border-width: 0 3px 3px 0;
  }
  
  .check-box-custom-remember-white:active input ~ .checkmark_white:after {
    display: block;
  }
  
  .check-box-custom-remember-white input:checked ~ .checkmark_white {
    border: 1px solid #fff !important;
  }
  
  .checkmark_white:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .check-box-custom-remember-white input:checked ~ .checkmark_white:after {
    display: block;
    border: 1px solid #fff;
    border-width: 0 3px 3px 0;
  }
  
  .check-box-custom-remember-white .checkmark_white:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  /* Modal Modified CSS Layout Updated Here */
  .MuiDialog-paperScrollPaper {
    box-shadow: 0px 0px 100px -20px rgba(0, 0, 0, 0.25) !important;
    border-radius: 12px;
  
    #customized-dialog-title {
      padding: 0;
  
      button {
        top: 18px;
        right: 22px;
        background-color: #fff;
        z-index: 999999;
  
        svg {
          fill: #263238;
        }
      }
    }
  }
  
  .gap {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
  
  .btnOuter {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  
    @media only screen and (max-width: 820px) {
      flex-direction: column;
    }
  
    .outerBtnsCancel {
      .cancel_btn {
        height: 50px;
        width: 146px;
  
        @media only screen and (max-width: 568px) {
          width: 100%;
        }
      }
    }
  }
  
  .outerBtnsCancel {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    list-style: none;
  
    .btn {
      font-size: 18px;
      letter-spacing: 2px;
      flex: 1;
      height: 49px;
      flex: 1;
      border: 1px solid #032439;
      border-radius: 8px;
      background: none;
    }
  
    .reg {
      color: #fff;
      border: none;
      background-color: $primary-color;
    }
  }
  
  .choose_allergies {
    padding: 35px 0 0 0;
    display: flex;
    align-items: center;
      p {
        font-family: "Titillium Web";
        font-weight: 400;
        font-size: 20px;
        color: #263238;
        white-space: nowrap;
        
        span {
          color: #00acba;
        }
      }
  
    .radio_btn_stack {
      .radio_grid {
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #263238;
        }
  
        input {
          color: #00acba !important;
        }
      }
    }
  }
  
  .payment_info_card {
    .payment_info_card_head {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 700;
      font-size: 26px !important;
      line-height: 40px;
      text-transform: capitalize;
      color: #263238;
      margin-bottom: 0 !important;
    }
  }
  
  .payment_info_main {
    .save_btn {
      button {
        text-transform: capitalize;
      }
    }
  
    .payment_info_box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 103px 0px;
  
      @media only screen and (max-width: 1530px) {
        flex-direction: column;
        padding: 50px 0px;
      }
  
      .exeasting {
        h2 {
          font-family: "Titillium Web";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 37px;
          text-transform: capitalize;
          color: #263238;
  
          @media only screen and (max-width: 1530px) {
            text-align: center;
          }
        }
  
        .radio_label {
          span {
            svg {
              fill: #00acba;
            }
  
            font-family: "Titillium Web";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-transform: capitalize;
            color: #263238;
          }
        }
      }
  
      .devide_box {
        border-left: 2px dashed #00acba;
        height: 120px;
  
        @media only screen and (max-width: 1530px) {
          border: none;
          height: 30px;
        }
      }
  
      .new_card_btn {
        button {
          width: 198px;
          height: 46px;
          border: 2px solid #02223a;
          border-radius: 8px;
          font-family: "Titillium Web";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          text-transform: capitalize;
          color: #02223a !important;
        }
      }
    }
  }
  