.product-page {
  height: 100%;

  .main-content-wrapper-full {
    height: 100%;
    overflow-y: auto;
    .main-content-wrap {
      background-color: #fff;
      height: 100%;
      .heading_top {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        color: #263238;
        padding-bottom: 40px;
      }
      .productLayout {
        height: 100%;
      }
      .product_content {
        height: 100%;
        flex-direction: row !important;

        @media only screen and (max-width: 1300px) {
          flex-direction: column !important;
        }

        .product_content_top {
          flex-direction: row !important;

          @media only screen and (max-width: 1300px) {
            max-width: 100% !important;
            flex-direction: column !important;
          }
        }

        .product_content_bottom {
          flex-direction: row !important;

          @media only screen and (max-width: 1300px) {
            max-width: 100% !important;
            flex-direction: column !important;
          }
        }
      }

      .product_dropdown_side {
        border-right: 2px solid rgba(38, 50, 56, 0.08);
        height: 100%;
        padding: 10px 10px 0 0;

        @media only screen and (max-width: 1200px) {
          border-right: none;
        }

        .accordian {
          .accordian_btn {
            background: none;
            border: none !important;
            outline: none;

            P {
              font-family: 'Titillium Web';
              font-weight: 700;
              font-size: 20px;
              color: #263238;
            }

            &::before {
              display: none;
            }
          }

          .MuiSvgIcon-root {
            fill: #00acba;
          }

          span {
            font-family: 'Titillium Web';
            font-weight: 400;
            font-size: 20px;
            color: rgba(38, 50, 56, 0.7);
            white-space: nowrap;

            // &:hover {
            //   overflow: visible;
            // }
          }
        }

        .filter {
          padding: 50px 0;
          display: flex;
          align-items: center;
          padding-left: 16px;

          ul {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            gap: 10px;
            padding: 0;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: 'Titillium Web';
              font-style: normal;
              font-weight: 700;
              font-size: 22px;
              text-transform: capitalize;
              color: #263238;
            }
          }
        }
      }

      .product_scroll {
        padding: 20px 0;
        padding-top: 0;
        margin-top: 15px;

        .product_stack {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          @media only screen and (max-width: 768px) {
            justify-content: center;
          }

          .product_out {
            position: relative;
            text-align: center;
            padding: 30px 15px 15px 15px;
            box-shadow: -1px 4px 6px 0px #d2d2d2;
            margin: 15px 22px 0 10px;
            border-radius: 5px;
            // max-width: 310px !important;
            max-height: 100%;
            width: 33.33%;
            height: 100%;
            min-height: 400px;
            @media only screen and (max-width: 568px) {
              width: 100%;
              margin-top: 25px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1440px) {
              max-width: 245px !important;
            }
            @media only screen and (min-width: 1440px) and (max-width: 1480px) {
              max-width: 280px !important;
            }
            @media only screen and (min-width: 1480px) {
              max-width: 290px !important;
            }

            // .product_image {
            .product-short-img-block {
              // margin-right: 10px;
              width: 100% !important;

              .product-short-img {
                padding-top: 85%;
                width: 100%;
                background-color: #f0f0f0;
                background-image: url('../../../assets/icons/Klarity Drops.svg');
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: contain !important;
                object-fit: contain;
                object-position: center;
              }

              h5 {
                @media only screen and (max-width: 991px) {
                  font-size: 12px;
                }
              }
            }

            // }

            .Product_name {
              font-family: 'Titillium Web';
              font-style: normal;
              font-weight: 600;
              font-size: 17px;
              line-height: 33px;
              margin-top: 13px;
              text-align: center;
              text-transform: capitalize;
              color: #263238;
              // word-break: break-all;
              min-height: 65px;
            }

            .Product_qty,
            .Product_detail {
              font-family: 'Titillium Web';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: rgba(38, 50, 56, 0.6);
            }

            .Product_qty {
              padding-bottom: 5px;
            }

            .favorite_product {
              position: absolute;
              top: 0;
              right: 14px;
              top: -2px;

              ul {
                list-style: none;
                display: flex;

                li {
                  p {
                    font-family: 'Titillium Web';
                    font-weight: 700;
                    font-size: 8px;
                    line-height: 83%;
                    text-align: center;
                    letter-spacing: -0.02em;
                    text-transform: capitalize;
                    color: #00acba;
                  }
                }
              }
            }
          }
        }
      }

      .surgical_btn {
        margin: 10px 0;

        span {
          margin-left: 5px;
        }
      }

      .searh-patient-inputfield {
        position: relative;

        label {
          left: 15px;
        }

        label[data-shrink='true'] {
          color: $primary-color;
        }

        input {
          padding-left: 15px;
        }

        .MuiAutocomplete-clearIndicator {
          margin-right: 8px;
        }

        .MuiAutocomplete-popupIndicator {
          opacity: 0;
        }

        .MuiInputBase-colorPrimary::after,
        .MuiInputBase-colorPrimary.Mui-focused::after {
          border-color: $primary-color;
        }

        .search-inputfield-btn {
          position: absolute;
          right: 8px;
          bottom: 10px;
          pointer-events: none;
        }
      }
    }

    .product_page {
      padding: 30px 10px;

      @media only screen and (max-width: 568px) {
        padding: 15px 10px !important;
      }
    }
  }
}

.product_outerInfo_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  .productInfos {
    flex: 1;
  }
  .productInfosbtn {
    height: 50px;
  }
}
.checkbox_outer {
  overflow: hidden;
  text-overflow: ellipsis;
}
